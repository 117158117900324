/* Основные стили для страницы товара */
.product-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Центрирование страницы */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-page h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .product-page p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Стили для изображения товара */
  .product-page img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Стили для контейнера опций */
  .options-container {
    margin-top: 20px;
  }
  
  .option-category {
    margin-bottom: 20px;
  }
  
  .option-category h3 {
    font-size: 20px;
    color: #4d4d4d;
    margin-bottom: 10px;
  }
  
  /* Стили для отдельных опций */
  .options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .option {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: calc(33.333% - 10px); /* Три опции в ряд с учетом отступов */
    text-align: center;
    cursor: pointer;
    transition: border 0.3s, box-shadow 0.3s;
  }
  
  .option img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .option.selected {
    border: 2px solid #4d4d4d; /* Выделение выбранной опции */
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  }
  
  .option p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
  
  /* Стили для кнопки добавления в корзину */
  .add-to-cart-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #4d4d4d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-button:hover {
    background-color: #cfcbcb;
  }
  
  .modal-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; /* Центрируем текст */
    padding-top: 10px; /* Добавляем отступ сверху для заголовка */
  }
  
  .close-icon {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    fill: #333;
    transition: fill 0.3s;
  }
  
  .close-icon:hover {
    fill: #007BFF;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }