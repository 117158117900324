/* src/components/Orders.css */
.orders-container {
  padding: 16px;
  margin: 50px 0;
  border-radius: 5px; /* Скругленные углы */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
  transition: box-shadow 0.3s; /* Плавный переход для тени */
}

.orders-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Увеличение тени при наведении */
}

.order-item {
  display: flex;
  align-items: center;
  border: 2px solid rgb(165, 174, 165);
  border-radius: 5px; /* Скругленные углы */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
  transition: box-shadow 0.3s; /* Плавный переход для тени */
  padding: 8px;
  margin: 8px 0;
}

.history-button:disabled {
  background-color: #ccc; /* Цвет заблокированной кнопки */
  color: #666; /* Цвет текста на кнопке */
  cursor: not-allowed; /* Изменение курсора */
  opacity: 0.7; /* Прозрачность */
  border: 1px solid #999; /* Граница */
}

.order-item-image {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.order-item-details {
  flex-grow: 1;
}

.order-item-name {
  padding: 4px;
  margin-bottom: 4px;
}

.order-item-modifiers {
  font-size: 12px;
  padding: 4px;
  list-style: none;
  margin: 0;
}

.order-item-quantity {
  padding: 4px;
  font-size: 16px;
}


.orders-container .history-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #FF6347;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orders-container .history-button:hover {
  background-color: #FF4500;
}

.close-button {
  display: block;
  margin: 20px auto; /* Центрирование по горизонтали */
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #FF6347;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #FF4500;
}

/* Общие стили контейнера заказов */
.orders-container {
  padding: 16px;
  margin: 50px 0;
  border-radius: 5px; /* Скругленные углы */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
  transition: box-shadow 0.3s; /* Плавный переход для тени */
}

.orders-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Увеличение тени при наведении */
}

.order-item {
  display: flex;
  align-items: center;
  border: 2px solid rgb(165, 174, 165);
  border-radius: 5px; /* Скругленные углы */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
  transition: box-shadow 0.3s; /* Плавный переход для тени */
  padding: 8px;
  margin: 8px 0;
}

.order-item-image {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.order-item-details {
  flex-grow: 1;
}

.order-item-name {
  padding: 4px;
  margin-bottom: 4px;
}

.order-item-modifiers {
  font-size: 12px;
  padding: 4px;
  list-style: none;
  margin: 0;
}

.order-item-quantity {
  padding: 4px;
  font-size: 16px;
}

/* Стили для кнопок */
.close-button,
.history-button,
.evaluate-button,
.modal-content button {
  display: block;
  margin: auto; /* Центрирование по горизонтали */
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #FF6347;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover,
.history-button:hover,
.evaluate-button,
.modal-content button:hover {
  background-color: #FF4500;
}

/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
}

.modal-content textarea {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
}

/* Стили для звёзд */
/* Увеличение размера серой звезды */
.star {
  cursor: pointer;
  font-size: 24px; /* Размер по умолчанию */
  transition: font-size 0.2s ease;
}

.star-empty {
  font-size: 40px; /* Увеличенный размер для серой звезды */
  position: relative; /* Смещение вниз */
  top: 5px; /* Опускает звезду на 2 пикселя вниз */
}

.star-filled {
  color: gold; /* Цвет для заполненной звезды */
}
/* Стили для рейтинга заказов */
.order-rating {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.order-rating .star-icon {
  margin-right: 4px;
}

.no-history-message {
  text-align: center;
  font-size: 18px;
  color: #555;
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
}