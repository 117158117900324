/* src/App.css */

/* Подключаем шрифты */
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
}

/* Стили для заголовков */
h1, h2, h3 {
  font-family: 'Gilroy';
  font-weight: bold;
}

/* Стили для основного текста */
p, li, span {
  font-family: 'Gilroy';
  font-weight: 600; /* или 500 для Medium, если он у вас есть */
}

/* Стили для третьего по величине текста */
small, .small-text {
  font-family: 'Gilroy';
  font-weight: normal;
}

/* Стили для кнопок */
button {
  font-family: 'Gilroy';
  font-weight: 600; /* Используйте 500 или 600 в зависимости от желаемой толщины */
  font-size: 16px; /* Укажите желаемый размер текста */
  line-height: 1.5; /* Укажите желаемую высоту строки */
  text-transform: uppercase; /* Преобразует текст кнопок в верхний регистр */
  padding: 10px 20px; /* Добавьте отступы для кнопки */
  border: none; /* Убирает рамку кнопки */
  border-radius: 5px; /* Закругленные углы, настройте по желанию */
  cursor: pointer; /* Указывает на интерактивность кнопки */
  transition: background-color 0.3s, color 0.3s; /* Анимация при наведении */
}

/* Стили при наведении на кнопку */
button:hover {
  background-color: #4d4d4d; /* Замените цвет на нужный */
  color: #fff; /* Измените цвет текста при наведении */
}

/* Дополнительный класс для больших кнопок */
button.large {
  font-size: 18px;
  padding: 15px 30px;
}

/* Дополнительный класс для маленьких кнопок */
button.small {
  font-size: 14px;
  padding: 5px 10px;
}

/* Стили для div */
div {
  font-family: 'Gilroy';
}

body {
  width: 100%;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

body.no-scroll {
  overflow: hidden; /* Отключает прокрутку */
}

.container {
  padding: 10px;
}


/* Стили для вопроса */
.question {
  text-align: center;
  margin-bottom: 20px;
}

.question h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.option-button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #FF7F50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-button:hover {
  background-color: #FF7F50;
}

/* Стили для карточек */
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  min-width: 150px;
  min-height: 220px; /* Установить минимальную высоту */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card h3 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #333;
}

.card p {
  margin: 5px 0;
  font-size: 1em;
  color: #666;
}

.card:hover {
  border: 2px solid #FF6347;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
  transform: scale(1.05);
}

#back-button, #cart-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #FFA07A;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#back-button:hover, #cart-button:hover {
  background-color: #FF7F50;
}

.add-to-cart-button {
  display: block;
  margin: 10px auto 0;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #FF6347;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #FF7F50;
}

.add-to-cart-button:active {
  background-color: #FF7F40; /* Ещё темнее оранжевый при нажатии */
  transform: scale(0.95); /* Уменьшаем размер кнопки для эффекта нажатия */
}

.cart-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.cart-controls button {
  background-color: #4d4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-controls button:hover {
  background-color: #4d4d4d;
}

.item-quantity {
  font-size: 1.2em;
  margin: 0 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

/* Полупрозрачный фон */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Черный цвет с прозрачностью */
  z-index: 999; /* На слой ниже корзины */
}

/* Стили для корзины */
.cart-sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000; /* Поверх overlay */
  height: 400px;
  display: flex;
  flex-direction: column;
}

.cart-sidebar.open {
  transform: translateY(0);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #4d4d4d;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
}

.cart-items {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex:1;
  
 
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  gap: 30px;
}

.cart-item-details {
  flex: 1;
}

.cart-item img {
  width: 50px;
  height: 50px;
}

/* Для WebKit браузеров */
::-webkit-scrollbar {
  width: 1px; /* Ширина вертикального скроллбара */
  height: 1px; /* Высота горизонтального скроллбара */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет ползунка */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона скроллбара */
}

/* Для Firefox */
* {
  scrollbar-width: thin; /* Узкий скроллбар */
  scrollbar-color: #888 #f1f1f1; /* Цвет ползунка и трека */
}

/* WebKit-браузеры */
::-webkit-scrollbar {
  width: 1px; /* Ширина вертикального скроллбара */
  height: 1px; /* Высота горизонтального скроллбара */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет ползунка */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона скроллбара */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Узкий скроллбар */
  scrollbar-color: #888 #f1f1f1; /* Цвет ползунка и трека */
}

.cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: right;
  margin-top: auto
}

.close-cart {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  text-transform: none; /* Добавлено свойство для отмены верхнего регистра */
}

.close-cart:hover {
  background-color: #888;
}

.order-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #1a1a1a;
  background-color: #FF7F50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.order-button:hover {
  background-color: #e27046;
}

.order-button:disabled {
  background-color: #ccc; /* Серый цвет для неактивной кнопки */
  color: #666; /* Более светлый текст */
  cursor: not-allowed;
}


.cart-header h2 {
  color: #000; /* Изменяем цвет заголовка на черный */
  font-family: 'Gilroy'; /* Устанавливаем шрифт Gilroy */
}

.close-cart {
  color: #000; /* Изменяем цвет текста кнопки на черный */
  font-family: 'Gilroy'; /* Устанавливаем шрифт Gilroy */
  font-weight: bold;
}
