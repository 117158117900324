/*src/components/ComboGroupDetails.css */
.combo-group-container {
  padding: 20px;
}

.combo-group {
  margin-bottom: 30px;
}

.combo-group h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.combo-products {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.combo-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 150px;
  padding: 10px;
  position: relative;
  transition: border 0.3s;
}

.combo-product-card.selected {
  border: 2px solid green;
}

.combo-product-image {
  width: 80px;
  height: auto;
}

.combo-product-info {
  text-align: center;
  margin-top: 10px;
}

.combo-product-info h4 {
  margin: 0;
  font-size: 1em;
}

.edit-button {
  color: #4d4d4d;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 5px;
}

.edit-button:hover {
  text-decoration: underline;
}
