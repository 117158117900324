.cartButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #FFA07A; /* Желтый фон */
    border-radius: 50%; /* Круглый контейнер */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cartButton:hover {
    background-color: #FF7F50;
  }
  
  .cartIcon {
    width: 30px;
    height: 30px;
  }
  
  .cartSidebarOpen + .cartButton {
    z-index: 900; /* Уходит под панель */
  }

  .itemCount {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }
  