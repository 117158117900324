/* src/components/CategoryContainer.css */

.variants {
  display: flex;
  justify-content: center; /* Центрируем иконки по горизонтали */
  gap: 10px;
  overflow-x: auto; /* Добавляем прокрутку при необходимости */
}

.variant {
  flex-shrink: 0;
  width: 15%; /* Устанавливаем размер иконок в процентах для адаптивности */
  max-width: 80px; /* Ограничиваем максимальный размер */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card p {
  text-align: left;
}

.variant img.variant-image {
  width: 80%; /* Размер изображения в процентах от контейнера */
  height: auto; /* Сохраняем пропорции изображения */
  object-fit: cover; /* Сохраняем пропорции изображения */
  margin: auto; /* Центрируем изображение */
}

.variant.selected {
  border: 2px solid #FF7F50; /* Выделение выбранного варианта */
  border-radius: 5px;
}

/* Стили для кнопок подкатегорий */
.subcategory-buttons {
  display: flex;
  justify-content: center; /* Центрируем кнопки по горизонтали */
  flex-wrap: wrap; /* Разрешаем кнопкам переноситься на новую строку */
  gap: 10px; /* Отступы между кнопками */
  margin: 20px 0; /* Отступы сверху и снизу */
}

.subcategory-buttons button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none; /* Убираем синюю обводку */
}

.subcategory-buttons button:hover {
  background-color: #f77344;
  color: #fff; /* Изменение цвета текста при наведении */
}

.subcategory-buttons button.active {
  background-color: #FF7F50;
  color: #fff;
  border: 1px solid #f77344; /* Изменение цвета границы для активной кнопки */
}

.subcategory-buttons button:focus,
.subcategory-buttons button:active {
  outline: none; /* Убираем обводку при фокусе и активном состоянии */
}

/* Дополнительно убираем обводку для всех кнопок и интерактивных элементов */
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  outline: none; /* Убираем обводку */
}

.product-image-container {
  position: relative;
  width: 100%; /* Задаём ширину контейнера */
  height: 300px; /* Фиксированная высота для всех изображений */
  overflow: hidden; /* Чтобы изображение не выходило за границы */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Задаём фоновый цвет, пока изображение загружается */
}

.product-image-container img {
  max-width: 100%; /* Адаптивное изменение ширины */
  max-height: 100%; /* Адаптивное изменение высоты */
  object-fit: cover; /* Сохраняем пропорции и центрируем изображение */
}

.product-labels {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 4px;
}

.product-label-icon {
  width: 20px;
  height: 20px;
}

.restaurant-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  fill: #000; /* Цвет иконки можно изменить */
  cursor: pointer;
}

.delivery-icon {
  position: absolute;
  bottom: 8px;
  right: 32px; /* Сдвигаем, чтобы не перекрывалась с другими иконками */
  width: 24px;
  height: 24px;
  fill: #000; /* Цвет иконки можно изменить */
  cursor: pointer;
}

.restaurant-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px; /* Увеличиваем отступы */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px; /* Увеличиваем максимальную ширину */
  width: 80%; /* Делаем ширину адаптивной */
  max-height: 60%; /* Устанавливаем максимальную высоту */
  overflow-y: auto; /* Добавляем скролл при переполнении */
}

.restaurant-modal h3 {
  margin-bottom: 20px;
  font-size: 1.4em; /* Увеличиваем размер шрифта заголовка */
}

.restaurant-modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.restaurant-modal li {
  margin-bottom: 15px; /* Увеличиваем отступы между элементами списка */
  font-size: 1.1em; /* Увеличиваем размер шрифта для элементов списка */
}

.restaurant-modal button {
  margin-top: 20px;
  padding: 10px 20px; /* Увеличиваем размер кнопки */
  background: #FF7F50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em; /* Увеличиваем размер шрифта кнопки */
}

.restaurant-modal button:hover {
  background: #fa7341; /* Добавляем эффект при наведении */
}

/* Стили для модального окна доставки */
.delivery-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px; /* Увеличиваем отступы */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px; /* Увеличиваем максимальную ширину */
  width: 80%; /* Делаем ширину адаптивной */
  max-height: 60%; /* Устанавливаем максимальную высоту */
  overflow-y: auto; /* Добавляем скролл при переполнении */
}

.delivery-modal h3 {
  margin-bottom: 20px;
  font-size: 1.4em; /* Увеличиваем размер шрифта заголовка */
}

.delivery-modal button {
  margin-top: 20px;
  padding: 10px 20px; /* Увеличиваем размер кнопки */
  background: #FF7F50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em; /* Увеличиваем размер шрифта кнопки */
}

.delivery-modal button:hover {
  background: #fa7341; /* Добавляем эффект при наведении */
}
/* Стили для карточек категорий */
#category-container .card {
  border-radius: 5px; /* Скругленные углы */
  padding: 10px;
  margin: 10px; /* Добавляем отступ между карточками */
  transition: box-shadow 0.3s;
}

#category-container .card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Эффект тени при наведении */
}
