.backButton {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    background-color: #FFA07A; /* Оранжевый фон */
    border-radius: 50%; /* Круглая кнопка */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, left 0.3s ease; /* Плавный переход для фона и горизонтального перемещения */
  }
  
  .backButton:hover {
    background-color: #FF7F50; /* Темнее при наведении */
  }
  
  .backIcon {
    width: 30px;
    height: 30px;
  }
  
  .cartSidebarOpen + .backButton {
    z-index: 900; /* Уходит под панель */
  }
  
  /* Кнопка видима, на своём месте */
  .visible {
    left: 20px; /* Положение кнопки на экране */
  }
  
  /* Кнопка уезжает вправо за экран */
  .hidden {
    left: -100vw; /* Уезжает вправо за пределы экрана */
  }
  