/* src/components/ProductDetailsModal.css */

/* Основные стили для модального окна */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 2000; /* Устанавливаем высокий z-index для перекрытия всех элементов */
  }
  
  .modal-content {
    background: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    position: relative;
    overflow-y: auto;
    max-height: 80%; /* Ограничиваем максимальную высоту для удобной прокрутки */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #FF7F50; /* Цвет при наведении */
  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .modal-content p {
    margin: 10px 0;
    color: #666;
  }
  
  /* Стили для контейнера опций */
  .options-container {
    margin-top: 20px;
  }
  
  .option-category {
    margin-bottom: 15px;
  }
  
  .option-category h3 {
    font-size: 18px;
    color: #FF7F50;
    margin-bottom: 10px;
  }
  
  /* Стили для отдельных опций */
  .options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .option {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: calc(33.333% - 10px); /* Три опции в ряд с учетом отступов */
    text-align: center;
    cursor: pointer;
    transition: border 0.3s, box-shadow 0.3s;
  }
  
  .option img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .option.selected {
    border: 2px solid #FF7F50; /* Выделение выбранной опции */
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  }
  
  .option p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
  
  /* Стили для кнопки добавления в корзину */
  .add-to-cart-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #4d4d4d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-button:hover {
    background-color: #4d4d4d;
  }
  
  .option.disabled {
    background: #e0e0e0; /* Серый цвет */
    cursor: not-allowed; /* Убираем возможность клика */
    pointer-events: none; /* Полностью отключаем клики */
    border: 1px solid #ccc; /* Меняем границу для некликабельных */
  }
  
  .add-to-cart-button {
    position: sticky;
    bottom: 0;
    background-color: #fff; /* Цвет фона, чтобы кнопка не сливалась с контентом */
    padding: 10px 0; /* Отступы сверху и снизу */
    text-align: center; /* Центрирование кнопки */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Тень для отделения от контента */
  }
  