/* src/components/CartLoader.css */
.cart-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Высота может быть адаптирована под размер корзины */
}

.spinner_l {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #4d4d4d;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
